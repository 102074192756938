import axios from "../../../services/Api";
import router from "../../../router/index";


// Comitea la acción del login
export const login = async ({ commit }) => {
  await axios.get("/front-catalog/customers").then(({ data }) => {
    commit("SET_USER", data);
    commit("SET_AUTHENTICATED", true);
    router.push({ name: "Home" });
  });
};

// Comitea la acción del logout
export const logout = async ({ commit }) => {
  await commit("SET_USER", {});
  commit("SET_AUTHENTICATED", false);
};

// Recupera información del perfil de usuario una vez hechos cambios 
export const setUser = async ({ commit }) => {
  await axios.get("/front-catalog/customers").then(({ data }) => {
    commit("SET_USER", data);
  });
};
