import { mapActions } from "vuex";

export default {
  computed: {
    settings() {
      return this.$store.state.settings.settings;
    },
  },
  methods: {
    ...mapActions("settings", ["getSettings"]),
  },
  mounted() {
    this.getSettings();
  },
};
