import Api from "../Api";
import i18n from "../../i18n";

export default {
  async allTransactions() {
    if (i18n.global.locale === "en") {
      try {
        const fetchOrders = await Api.get(
          "front-catalog/" + i18n.global.locale + "/customers"
        );
        return fetchOrders;
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        const fetchOrders = await Api.get("front-catalog/customers");
        return fetchOrders;
      } catch (error) {
        console.log(error);
      }
    }
  },
};
