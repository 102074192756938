export default {
  "General": {
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current Balance"])},
    "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exchange"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Summary"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page not found. Please go back to Home or contact us through our email"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
    "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
    "panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet"])},
    "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wait a moment..."])},
    "problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An unexpected error has occurred"])},
    "mail-example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["user", "@", "example.com"])},
    "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Available points"])},
    "no-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No available data"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])}
  },
  "Login": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create account"])},
    "your-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your email"])},
    "your-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password"])},
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname"])},
    "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fecha de nacimiento"])},
    "repeat-your-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeat your password"])},
    "login-now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "login-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "have-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Do you have an account?"])},
    "create-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea an account"])},
    "privacy-politics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy Policy"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequently Asked Questions"])},
    "try-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again"])},
    "legal-disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Legal"])},
    "wrong-credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WRONG CREDENTIALS"])},
    "wrong-credentials-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid email or password"])},
    "wrong-credentials-register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We could not carry out your registration"])},
    "go-back-login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Sign In"])},
    "introduce-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, introduce your name"])},
    "introduce-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, introduce your surname"])},
    "user-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User successfully created"])},
    "user-not-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are sorry, we could not create the user"])},
    "error-register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check that the passwords match or use another email"])},
    "email-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, introduce a correct mail"])},
    "accept-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I accept the privacy policies"])},
    "accept-policy-text-part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I accept the "])},
    "accept-policy-link-part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privacy policies"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Close"])},
    "please-accept-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you want to continue, you must accept the privacy policies"])},
    "web-omniwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Do you want to go to the wbesite?"])},
    "forgotten-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have you forgotten the password?"])},
    "web-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go"])},
    "go-back-app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back to the App"])},
    "get-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperar contraseña"])},
    "introduce-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Por favor, introduce tu correo actual de"])},
    "send-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We will email your new password."])},
    "current-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Current password"])},
    "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change password"])},
    "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New password"])},
    "confirm-new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm new password"])},
    "success-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password has been successfully changed"])},
    "problem-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure your email is correct and your passwords match."])},
    "problem-password-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Make sure your email is correct."])},
    "get-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to get a recovery link"])},
    "apply-for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply for"])},
    "check-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Check the email to recover your password"])},
    "go-back-app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back"])},
    "SignUp": {
      "your-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your name"])},
      "your-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password"])},
      "your-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your phone"])},
      "min-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose a safer password. Try a combination of letters and numbers"])},
      "min-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your password should have at least 9 characters"])},
      "wrong-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wrong date"])}
    }
  },
  "Profile": {
    "my-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My profile"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "success-edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfuly edited"])},
    "fail-edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are sorry, we could not edit your information"])},
    "go-back-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go back to Home"])},
    "edit-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit again"])},
    "personal-address": {
      "my-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My addresses"])},
      "shipping-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shipping addresses"])},
      "billing-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Billing addresses"])},
      "add-shipping-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new shipping address"])},
      "add-billing-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new billing address"])},
      "please-introduce-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, introduce an address"])},
      "please-introduce-postalcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, introduce your Postal Code"])},
      "please-introduce-city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, introduce your city"])},
      "please-introduce-province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, introduce your province"])},
      "please-introduce-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, introduce your country"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
      "postalcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postal Code"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["City"])},
      "province": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Province"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comments"])},
      "confirm-delete-dialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you want to delete this address?"])}
    },
    "personal-data": {
      "my-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personal data"])},
      "please-introduce-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, introduce your email"])},
      "please-introduce-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, introduce your name"])},
      "please-introduce-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, introduce your surname"])},
      "please-introduce-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, introduce your phone"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
      "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Surname"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])}
    },
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privacy"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log out"])},
    "logout-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loggin out..."])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "are-you-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure?"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Languages"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, wait a second"])}
  },
  "Home": {
    "catalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalog"])},
    "see-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all"])},
    "last-movements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last movements"])},
    "see-movements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See movements"])}
  },
  "ControlPanel": {
    "control-panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Control panel"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet"])},
    "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My card"])},
    "hello-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to the Control Panel"])},
    "welcome2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From here you can see your available balance, your default address and the code with the card number of your loyalty account"])},
    "card-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Card number"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "download-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download your card"])},
    "info-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can now start accumulating and redeeming points. You'll also be the first to know about sales, special offers and exclusive events."])}
  },
  "BuyingProcess": {
    "transactions": {
      "past-transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Past Transactions"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
      "expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expenses"])},
      "incomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Incomes"])},
      "transaction-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order number"])},
      "transaction-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order date"])},
      "transaction-state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order state"])},
      "transaction-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Order information"])},
      "transaction-exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exchanged Order"])},
      "transaction-reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference number"])},
      "manage-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manage my order"])},
      "continue-buying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continue buying"])},
      "processing-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processing your order"])},
      "no-processing-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are sorry, we cannot carry out your order"])},
      "no-address-chosen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No address selected"])},
      "add-new-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add new address"])},
      "use-same-address-invoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Use the same address for billing"])},
      "add-note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add comment"])},
      "note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment"])},
      "go-back-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to Home"])}
    },
    "products": {
      "availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Availability"])},
      "lack-of-points-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You need"])},
      "lack-of-points-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to get this product"])},
      "want-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want it"])},
      "stock1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No stock"])},
      "stock2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last units"])},
      "stock3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock available"])},
      "available-points-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your"])},
      "available-points-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["available"])},
      "buy-confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Receipt confirmation"])},
      "total-expense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total cost"])},
      "final-balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Final balance"])},
      "home-delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home Delivery"])},
      "pickup-store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick up at the store"])},
      "extra-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home delivery option has an extra cost of"])},
      "please-choose-option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, choose an option"])},
      "please-choose-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please, specify an address"])},
      "send-to-my-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to send my product to the address of my profile"])},
      "new-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New address"])},
      "my-transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Your Order"])},
      "exchanged-successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["It was exchanged successfully."])},
      "will-get-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You will receive an email with the order details. We are waiting for you!"])},
      "no-stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no stock yet"])},
      "no-shops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There is no shops yet"])},
      "no-addresses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are no addresses"])},
      "last-units": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last units"])},
      "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In stock"])},
      "see-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See all"])},
      "no-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["By now, we do not have products"])},
      "no-points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No available points"])},
      "search-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search for a product..."])},
      "show-stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show only in stock"])},
      "available-points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show only with available points"])},
      "show-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all"])},
      "choose-option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose an option"])},
      "filter-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filters"])},
      "filter-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All"])},
      "filter-stock-available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has stock available"])},
      "filter-enough-points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can afford"])},
      "sort-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorting"])},
      "sort-a-z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alphabetical: A-z"])},
      "sort-z-a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alphabetical: Z-a"])},
      "sort-cheaper-first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price: Low to high"])},
      "sort-expensive-first": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price: High to low"])}
    }
  },
  "FAQ": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequently Asked Questions (FAQ)"])},
    "title-about-app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questions about the catalog"])},
    "title-about-login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login and password"])},
    "title-about-buying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buying"])},
    "app-question-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where can I download the catalog?"])},
    "app-question-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How much does the catalog cost?"])},
    "app-question-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What is included in the Omniwallet catalog?"])},
    "app-question-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" I am unable to load the catalog or the products inside. What happened?"])},
    "app-question-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Which phones can use the catalog?"])},
    "app-answer-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Omniwallet catalog can be downloaded from the mobile browser. There are mobiles in which the download notice appears in the same browser, others that can be found in settings."])},
    "app-answer-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Omniwallet catalog is completely free."])},
    "app-answer-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The catalog offers several options to the customer. It allows you to redeem products for points, check past transactions and make changes to your profile."])},
    "app-answer-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First of all, please check your internet connection. If you keep getting errors, please log out and re-authenticate again."])},
    "app-answer-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The catalog is currently available on iOS and Android."])},
    "login-question-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How do I login?"])},
    "login-question-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How do I log out?"])},
    "login-question-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I have lost my username and password"])},
    "login-question-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I can't download the information. What happened?"])},
    "login-answer-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please enter your email and password. Your password is the default card number assigned in the email. If you have registered through the catalog, the password is the one indicated in the form."])},
    "login-answer-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please go to the profile and under “privacy”, click “Logout”."])},
    "login-answer-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please contact omniwallet", "@", "omniwallet.net."])},
    "login-answer-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To access the catalog you must use the same email and password associated with your account. In case of problems, please log out. If the problem is not solved, contact support to the client omniwallet", "@", "omniwallet.net."])},
    "buying-question-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can I buy a product?"])},
    "buying-question-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How can I see information about a past transaction?"])},
    "buying-question-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How do I edit my profile?"])},
    "buying-question-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["How do I check my card number?"])},
    "buying-answer-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Follow the following steps: Go to the main page or the products page. Then, click on a product. If it is available and you have enough points, click redeem. Next, specify the product destination: store pickup or home delivery. If you choose the home delivery option you can add a new address. Finally, click on buy and proceed with the transaction."])},
    "buying-answer-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please go to the transactions page and click on any. You can see both expenses and income."])},
    "buying-answer-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please go to the profile page and click on the pencil."])},
    "buying-answer-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please go to the control panel and there you will see your card number."])}
  }
}