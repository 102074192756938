export default {
  "General": {
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Desa"])},
    "balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo actual"])},
    "exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canviar"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprar"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resum"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pàgina no trobada. Si us plau, torna a l'inici o poseu-vos en contacte amb nosaltres a través del nostre correu electrònic"])},
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inici"])},
    "products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Productes"])},
    "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perfil"])},
    "panel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panel"])},
    "transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaccions"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuar"])},
    "wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esperi un moment..."])},
    "problem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ha passat un error inesperat"])},
    "mail-example": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usuari", "@", "exemple.com"])},
    "points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punts disponibles"])},
    "no-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hi ha dades disponibles"])}
  },
  "Login": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicia la sessió"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea un compte"])},
    "your-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El teu email"])},
    "your-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La teva contrasenya"])},
    "mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrasenya"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telèfon"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognom"])},
    "repeat-your-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repeteix la contrasenya"])},
    "login-now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "login-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inicia sessió"])},
    "have-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tens un compte?"])},
    "create-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crea un compte"])},
    "privacy-politics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Política de privadesa"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntes freqüents"])},
    "try-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna a intentar-ho"])},
    "legal-disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avís legal"])},
    "wrong-credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CREDENCIALS INCORRECTES"])},
    "wrong-credentials-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Correu electrònic o contrasenya no vàlids"])},
    "wrong-credentials-register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hem pogut efectuar el registre"])},
    "go-back-login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna a Inici de sessió"])},
    "introduce-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si us plau, introdueixi el vostre nom"])},
    "introduce-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si us plau, introdueixi el vostre cognom"])},
    "user-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usuari creat exitosament"])},
    "user-not-created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ho sentim, no hem pogut crear l'usuari"])},
    "error-register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comproveu que les contrasenyes coincideixin o utilitzi un altre correu electrònic"])},
    "email-required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si us plau, introdueix un email vàlid"])},
    "accept-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepto les polítiques de privacitat"])},
    "please-accept-policy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si us plau, per continuar accepta les polítiques de privacitat"])},
    "web-omniwallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Vol accedir a la pàgina web?"])},
    "forgotten-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has oblidat la contrasenya?"])},
    "web-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accedir"])},
    "go-back-app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tornar a la App"])},
    "get-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recuperar contrasenya"])},
    "introduce-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si us plau, introdueix el correu actual de"])},
    "send-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["T'enviarem un mail per recuperar la contrasenya"])},
    "current-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrasenyaa actual"])},
    "change-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cambiar contrasenya"])},
    "new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova contrasenya"])},
    "confirm-new-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmació de nova contrasenya"])},
    "success-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La teva contrasenya ha sigut cambiada exitosament"])},
    "problem-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegureu-vos que el vostre correu electrònic és correcte i que les vostres contrasenyes coincideixen."])},
    "problem-password-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assegureu-vos que el vostre correu electrònic és correcte"])},
    "get-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["per obtindre un link de recuperació"])},
    "apply-for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sol·licitar"])},
    "check-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulteu el correu electrònic per recuperar la contrasenya"])},
    "go-back-app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tornar enrere"])},
    "SignUp": {
      "your-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El teu nom"])},
      "your-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El teu cognom"])},
      "your-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El teu telèfon"])},
      "min-password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trieu una contrasenya més segura. Utilitzi una combinació de lletres i números"])},
      "min-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La seva contrasenya ha de tenir al menys 9 caràcters"])}
    }
  },
  "Profile": {
    "my-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El meu perfil"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idioma"])},
    "success-edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informació editada amb èxit"])},
    "fail-edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ho sentim, no hem pogut editar la informació"])},
    "go-back-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tornar a l'Inici"])},
    "edit-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna a editar"])},
    "personal-address": {
      "my-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les meves adreces"])},
      "please-introduce-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si us plau, introduïu la vostra adreça"])},
      "please-introduce-postalcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si us plau, introduïu el vostre Codi Postal"])},
      "please-introduce-city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si us plau, introduïu la vostra ciutat"])},
      "please-introduce-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si us plau, introduïu el vostre país"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adreça"])},
      "postalcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Codi postal"])},
      "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ciutat"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["País"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comentaris"])}
    },
    "personal-data": {
      "my-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dades personals"])},
      "please-introduce-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si us plau, introduïu el vostre email"])},
      "please-introduce-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si us plau, introduïu el vostre nom"])},
      "please-introduce-surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si us plau, introduïu el vostre cognom"])},
      "please-introduce-phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si us plau, introduïu el vostre telèfon"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "surname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognom"])},
      "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telèfon"])}
    },
    "privacy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privadesa"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tancar sessió"])},
    "logout-in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tancant la sessió..."])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacta"])},
    "are-you-sure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿Està segur?"])},
    "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sí"])},
    "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajustaments"])},
    "languages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Idiomes"])}
  },
  "Home": {
    "catalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catàleg"])},
    "see-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veure tots"])},
    "last-movements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últims moviments"])},
    "see-movements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veure moviments"])}
  },
  "ControlPanel": {
    "card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La meva targeta"])},
    "hello-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hola"])},
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benvingut al tauler de control del compte"])},
    "welcome2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des d'aquí pots veure el teu saldo disponible, la teva adreça predeterminada i el codi amb el número de targeta del teu compte de fidelització"])},
    "card-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº de la targeta"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adreça"])},
    "download-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descarregar targeta"])},
    "info-card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ja pots començar a acumular i bescanviar punts. També seràs el primer a assabentar-se de les vendes, ofertes especials i esdeveniments exclusius"])}
  },
  "BuyingProcess": {
    "transactions": {
      "past-transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaccions passades"])},
      "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tots"])},
      "expenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Despeses"])},
      "incomes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingressos"])},
      "transaction-number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de comanda"])},
      "transaction-date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data de comanda"])},
      "transaction-state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estat de la comanda"])},
      "transaction-information": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informació de la comanda"])},
      "transaction-exchange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comanda bescanviada"])},
      "transaction-reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nº referència"])},
      "manage-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestionar la meva comanda"])},
      "continue-buying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seguir comprant"])},
      "processing-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Processant la seva comanda"])},
      "no-processing-order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ho sentim, no podem bescanviar el seu producte"])},
      "go-back-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torna a l'inici"])}
    },
    "products": {
      "availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibilitat"])},
      "lack-of-points-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et falten"])},
      "lack-of-points-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["punts per bescanviar aquest producte"])},
      "want-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ho vull"])},
      "stock1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hi ha estoc"])},
      "stock2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimes unitats"])},
      "stock3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En estoc"])},
      "available-points-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Els teus"])},
      "available-points-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disponibles"])},
      "buy-confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmació de compra"])},
      "total-expense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cost total"])},
      "final-balance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saldo final"])},
      "home-delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enviament a domicili"])},
      "pickup-store": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recollida a la botiga"])},
      "extra-price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'opció d'enviament a domicili té un cost extra de"])},
      "please-choose-option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si us plau, esculli una opció"])},
      "please-choose-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si us plau, especifiqueu una adreça"])},
      "send-to-my-profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vull enviar el meu producte a l'adreça del meu perfil"])},
      "new-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nova adreça d'enviament"])},
      "my-transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La seva comanda"])},
      "exchanged-successfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ha estat bescanviat satisfactòriament."])},
      "will-get-email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rebreu un correu electrònic amb les dades de la comanda. Us hi esperem!"])},
      "no-stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hi ha stock"])},
      "no-shops": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No hi ha tendes"])},
      "last-units": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Últimes unitats"])},
      "stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En stock"])},
      "see-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veure tots els producte"])},
      "no-products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encara no tenim productes disponibles"])},
      "no-points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No disposa de suficients punts"])},
      "search-product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerca un producte..."])},
      "show-stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra productes en stock"])},
      "available-points": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra amb punts disponibles"])},
      "show-all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mostra tots"])},
      "choose-option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Escull una opción"])}
    }
  },
  "FAQ": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntes freqüents (FAQ)"])},
    "title-about-app": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preguntes sobre el catàleg"])},
    "title-about-login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inici de sessió i contrasenya"])},
    "title-about-buying": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compres"])},
    "app-question-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¿On puc baixar  el catàleg?"])},
    "app-question-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quant costa  el catàleg?"])},
    "app-question-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Què ve inclòs a  el catàleg d'Omniwallet?"])},
    "app-question-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sóc incapaç de carregar el catàleg o els productes que hi ha a dins. Què ha passat?"])},
    "app-question-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quins mòbils poden utilitzar  el catàleg?"])},
    "app-answer-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Omniwallet es pot descarregar des del navegador del mòbil. Hi ha mòbils on l'avís de descàrrega apareix al mateix navegador, altres que es pot trobar en configuració."])},
    "app-answer-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El Omniwallet és totalment gratuïta."])},
    "app-answer-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El ofereix diverses opcions al client. Permet bescanviar productes per punts, consultar transaccions passades i fer canvis al perfil."])},
    "app-answer-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primer de tot, si us plau, comproveu la connexió a Internet. En cas que continueu donant errors, tanqueu sessió i torneu a autenticar-se de nou."])},
    "app-answer-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El està actualment disponible a iOS i Android."])},
    "login-question-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Com inici sessió?"])},
    "login-question-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Com puc tancar la sessió?"])},
    "login-question-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["He perdut el meu usuari i contrasenya"])},
    "login-question-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No puc descarregar la informació. Què ha passat?"])},
    "login-answer-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si us plau, introduïu el vostre correu electrònic i la vostra contrasenya. La vostra contrasenya és el número de targeta per defecte assignada al correu electrònic. En el cas que s'hagi registrat a través de l'App, la contrasenya és la indicada al formulari."])},
    "login-answer-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si us plau, dirigiu-vos al perfil i en “privadesa”, feu clic a “Tancar sessió”."])},
    "login-answer-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si us plau, poseu-vos en contacte amb omniwallet", "@", "omniwallet.net."])},
    "login-answer-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per accedir a el heu d'usar el mateix correu electrònic i contrasenya associat al vostre compte. En cas de problemes, si us plau, tanqueu sessió. Si el problema no se soluciona, poseu-vos en contacte amb atenció al client omniwallet", "@", "omniwallet.net."])},
    "buying-question-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Com puc comprar un producte?"])},
    "buying-question-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Com puc veure informació d'una transacció passada?"])},
    "buying-question-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Com edito el meu perfil?"])},
    "buying-question-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Com consulto el meu número de targeta?"])},
    "buying-answer-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segueix els passos següents: Adreceu-vos a la pàgina principal oa la pàgina de productes. Després, feu clic en un producte. Si està disponible i té punts suficients, feu clic a bescanviar. Seguidament, especifiqueu el destinació del producte: recollida a botiga o enviament a domicili. Si escolliu l'opció enviament a domicili podeu afegir una nova adreça. Finalment, feu clic a comprar i procediu amb la transacció."])},
    "buying-answer-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si us plau, dirigiu-vos a la pàgina transaccions i feu clic a qualsevol. Podeu consultar tant despeses com ingressos."])},
    "buying-answer-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si us plau, dirigiu-vos a la pàgina de perfil i feu clic al llapis."])},
    "buying-answer-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si us plau, dirigiu-vos al tauler de control i allà podreu veure el vostre número de targeta."])}
  }
}